<template>
    <div class="layout-topbar justify-content-between">
        <button v-if="isAuthenticated" class="p-link layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <router-link to="/" class="logo">
            <img :src="logoUrl" alt="logo" />
        </router-link>

        <!-- <button  @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
            <i class=""></i>
        </button> -->

        <Menu ref="menu" :model="items" :popup="true" />
        <button v-if="isAuthenticated" type="button" class="p-link layout-topbar-button" icon="pi pi-user" @click="toggleMenu"><i class="pi pi-user"></i></button>

        <!-- <Button type="button" v-if="isAuthenticated" icon="pi pi-user" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" /> -->
        <!-- <Menu v-if="topbarMenuActive" :model="items" class="topbar-submenu" /> -->
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { postData } from '@/api';

const store = useStore();

const { layoutConfig, onMenuToggle } = useLayout();
const items = ref([
    {
        label: 'Profile',
        icon: 'pi pi-user-edit',
        command: () => {
            router.push('/profile');
        }
    },
    {
        separator: true
    },
    {
        label: 'logout',
        icon: 'pi pi-sign-out',
        command: async () => {
            await postData('/api/auth/user/logout');
            store.dispatch('auth/logout');
            router.push('/login');
        }
    }
]);
const isAuthenticated = computed(() => {
    if (store.getters['auth/isAuthenticated']) {
        layoutConfig.menuMode.value = 'static';
    } else {
        layoutConfig.menuMode.value = 'disabled';
    }

    return store.getters['auth/isAuthenticated'];
});

const toggleMenu = (event) => {
    menu.value.toggle(event);
};

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();
const menu = ref(null);

const logoUrl = computed(() => {
    return `/layout/images/madrivo-logo.svg`;
});

const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};

const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

// const bindOutsideClickListener = () => {
//     if (!outsideClickListener.value) {
//         outsideClickListener.value = (event) => {
//             if (isOutsideClicked(event)) {
//                 topbarMenuActive.value = false;
//             }
//         };
//         document.addEventListener('click', outsideClickListener.value);
//     }
// };
// const unbindOutsideClickListener = () => {
//     if (outsideClickListener.value) {
//         document.removeEventListener('click', outsideClickListener);
//         outsideClickListener.value = null;
//     }
// };
// const isOutsideClicked = (event) => {
//     if (!topbarMenuActive.value) return;

//     const sidebarEl = document.querySelector('.layout-topbar-menu');
//     const topbarEl = document.querySelector('.layout-topbar-menu-button');

//     return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
// };
</script>

<style lang="scss" scoped>
.logo {
    width: 250px;

    @media screen and (max-width: 768px) {
        width: 150px;
    }
}
.topbar-submenu {
    position: absolute !important;
    // background-color: white;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 100;
    right: 2rem;
    top: var(--topbar-height);

    // ul {
    //     list-style: none;
    //     margin: 0;
    //     padding: 0;

    //     li {
    //         padding: 8px 16px;
    //         &:hover {
    //             background-color: #f0f0f0;
    //         }
    //     }
    // }
}
</style>
