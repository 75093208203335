import { getData } from '@/api';

const state = {
    reconciliations: [],
    reconciliationDetail: {
        counts: {},
        reconciliation: {
            reconciliation_detail_items: []
        }
    },
    loading: true,
    detailLoading: false,
    uploadHistory: []
};

const getters = {
    reconciliations: (state) => {
        return state.reconciliations;
    },
    id: (state) => {
        return state.reconciliationDetail?.reconciliation?.id ?? null;
    },
    uniqueStatuses: (state) => {
        return state.reconciliations.reduce((acc, item) => {
            if (!acc.includes(item.status)) {
                acc.push(item.status);
            }
            return acc;
        }, []);
    },
    uniquePeriods: (state) => {
        return state.reconciliations.reduce((acc, item) => {
            if (!acc.includes(item.period)) {
                acc.push(item.period);
            }
            return acc;
        }, []);
    },
    reconciliationDetail: (state) => state.reconciliationDetail?.reconciliation,
    counts: (state) => state.reconciliationDetail?.counts,
    detailLoading: (state) => state.detailLoading,
    reconciliationsForAdvertiser: (state) => (advertiserId) => {
        return state.reconciliationDetail?.reconciliation?.reconciliation_items.filter((reconciliation) => reconciliation.network_advertiser_id === advertiserId);
    },
    reconciliationsForOffer: (state) => (offerId) => {
        return state.reconciliationDetail?.reconciliation?.reconciliation_items.filter((reconciliation) => reconciliation.network_offer_id === offerId);
    },
    reconciliationsForAffiliate: (state) => (affiliateId) => {
        return state.reconciliationDetail?.reconciliation?.reconciliation_items.filter((reconciliation) => reconciliation.reconciliation_item_details.some((detail) => detail.network_affiliate_id === affiliateId));
    },
    uploadHistory: (state) => state.uploadHistory
};

const actions = {
    async fetchReconciliations({ commit }) {
        try {
            const response = await getData('/api/accounting/reconciliations');
            const data = response.map((item) => ({
                ...item,
                start: new Date(item.start),
                end: new Date(item.end)
            }));
            commit('setReconciliations', data);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            commit('setLoading', false);
        }
    },
    async fetchReconciliationUploads({ commit, getters }) {
        const id = getters.id;
        if (!id) {
            return;
        }
        try {
            const response = await getData(`/api/accounting/reconciliations/${id}/uploads`);
            console.log('Reconciliation uploads', response);
            commit('setReconciliationUploads', response);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            commit('setLoading', false);
        }
    },
    async fetchReconciliationDetail({ commit }, payload) {
        // Check if the payload is a plain `id` or an object
        const id = typeof payload === 'object' ? payload.id : payload;
        const showLoader = typeof payload === 'object' ? payload.showLoader !== false : true;

        if (showLoader) {
            commit('setDetailLoading', true);
        }

        try {
            const data = await getData(`/api/accounting/reconciliations/${id}`);
            commit('setReconciliationDetail', data);
        } catch (error) {
            console.error('Error fetching reconciliation detail', error);
            throw error;
        } finally {
            commit('setDetailLoading', false);
        }
    },
    initFilters({ commit }) {
        commit('setFilters', {
            global: { value: null, matchMode: 'contains' },
            start: { operator: 'and', constraints: [{ value: null, matchMode: 'dateIs' }] },
            end: { operator: 'and', constraints: [{ value: null, matchMode: 'dateIs' }] },
            status: { operator: 'or', constraints: [{ value: null, matchMode: 'contains' }] }
        });
    },
    initDetailFilters({ commit }) {
        commit('setDetailFilters', {
            global: { value: null, matchMode: 'contains' },
            network_advertiser_id: { value: null, matchMode: 'contains' },
            network_offer_id: { value: null, matchMode: 'contains' },
            advertiser_leads: { value: null, matchMode: 'equals' },
            madrivo_leads: { value: null, matchMode: 'equals' },
            lead_difference: { value: null, matchMode: 'equals' },
            advertiser_revenue: { value: null, matchMode: 'equals' },
            madrivo_revenue: { value: null, matchMode: 'equals' },
            comment: { value: null, matchMode: 'contains' }
        });
    }
};

const mutations = {
    setReconciliations(state, reconciliations) {
        state.reconciliations = reconciliations;
    },
    setReconciliationDetail(state, detail) {
        state.reconciliationDetail = detail;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setDetailLoading(state, loading) {
        state.detailLoading = loading;
    },
    setFilters(state, filters) {
        state.filters = filters;
    },
    setDetailFilters(state, filters) {
        state.detailFilters = filters;
    },
    setReconciliationUploads(state, uploads) {
        state.uploadHistory = uploads;
    },
    updateItemDetail(state, { itemId, newItemDetail }) {
        const reconciliationItem = state.reconciliationDetail.reconciliation.reconciliation_items.find((item) => item.reconciliation_item_details.some((detail) => detail.id === itemId));

        if (reconciliationItem) {
            const detailIndex = reconciliationItem.reconciliation_item_details.findIndex((detail) => detail.id === itemId);
            if (detailIndex !== -1) {
                // Update the specific detail item
                reconciliationItem.reconciliation_item_details[detailIndex] = newItemDetail;
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
