import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { useStore } from 'vuex';

import advertiserRoutes from './routes/advertiserRoutes';
import authRoutes from './routes/authRoutes';
import errorRoutes from './routes/errorRoutes';
import creativeRoutes from './routes/creativeRoutes';
import questionnaireRoutes from './routes/questionnaireRoutes';
import wikiRoutes from './routes/wikiRoutes';
import newsletterRoutes from './routes/newsletterRoutes';
import publisherRoutes from './routes/publisherRoutes';

import vpnGuard from './guards/vpnGuard';
import authGuard from './guards/authGuard';
import wikiGuard from './guards/wikiGuard';
import accountingRoutes from './routes/accountingRoutes';
import internalToolRoutes from './routes/internalToolRoutes';
import reportingRoutes from './routes/reportingRoutes';
import { postData } from '../api';

const routes = [
    {
        path: '/',
        component: AppLayout,
        meta: { title: 'MyMadrivo | Home' },
        children: [
            {
                path: '/',
                component: () => import('@/views/pages/Home.vue'),
                name: 'home',
                meta: { title: 'MyMadrivo | Home', requiresAuth: true }
            },
            ...authRoutes,
            ...advertiserRoutes,
            ...errorRoutes,
            ...creativeRoutes,
            ...newsletterRoutes,
            ...publisherRoutes,
            ...questionnaireRoutes,
            ...wikiRoutes,
            ...accountingRoutes,
            ...internalToolRoutes,
            ...reportingRoutes,

            // Catch all Route - 404
            // !!! This should always be the last route !!!
            { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/pages/NotFound.vue'), meta: { title: 'MyMadrivo | 404' } }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    const store = useStore();
    store.commit('form/clearErrors');

    if (store.getters['auth/isAuthenticated']) {
        postData('/api/auth/user/log-activity', { route: to.name ?? to.path });
    }

    const isWikiRoute = to.matched.some((route) => route.path.startsWith('/wiki'));

    const passedAuthGuard = await authGuard(to, from, next, store);
    if (!passedAuthGuard) {
        store.commit('auth/setRedirectPath', to.fullPath); // Remember the route
        return;
    }

    const passedVPNGuard = await vpnGuard(to, from, next, store);
    if (!passedVPNGuard) {
        return;
    }

    if (isWikiRoute) {
        await wikiGuard(to, from, next, store);
    }

    const parentRoute = to.matched[1];
    if (parentRoute && parentRoute.meta && parentRoute.meta.title) {
        document.title = parentRoute.meta.title;
    } else {
        document.title = 'MyMadrivo';
    }

    next();
});
export default router;
