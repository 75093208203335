export default [
    {
        path: '/login',
        component: () => import('@/views/pages/auth/Login.vue'),
        name: 'login',
        meta: { title: 'MyMadrivo | Login' }
    },
    {
        path: '/profile',
        component: () => import('@/views/pages/auth/UserProfile/Profile.vue'),
        name: 'profile',
        meta: { title: 'MyMadrivo | Profile', requiresAuth: true }
    },
    {
        path: '/publisher/register',
        component: () => import('@/views/pages/auth/Register.vue'),
        name: 'registerPublisher',
        props: { userType: 'publisher' },
        meta: { title: 'MyMadrivo | Register' }
    },
    {
        path: '/advertiser/register',
        component: () => import('@/views/pages/auth/Register.vue'),
        name: 'registerAdvertiser',
        props: { userType: 'advertiser' },
        meta: { title: 'MyMadrivo | Register' }
    },
    {
        path: '/internal/employee/register',
        component: () => import('@/views/pages/auth/Register.vue'),
        name: 'registerEmployee',
        props: { userType: 'employee' },
        meta: { title: 'MyMadrivo | Register', isInternal: true }
    },
    {
        path: '/two-factor-authentication',
        component: () => import('@/views/pages/auth/TwoFactorAuthentication.vue'),
        name: 'two-factor-authentication',
        meta: { title: 'MyMadrivo | Two-Factor Authentication' }
    },
    {
        path: '/forgot-password',
        component: () => import('@/views/pages/auth/PasswordRecovery.vue'),
        name: 'forgotPassword',
        meta: { title: 'MyMadrivo | Forgot Password' }
    },
    {
        path: '/reset-password',
        component: () => import('@/views/pages/auth/ResetPassword.vue'),
        name: 'resetPassword',
        meta: { title: 'MyMadrivo | Reset Password' }
    }
];
