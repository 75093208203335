import { getData, postData, patchData } from '@/api';

const state = {
    formData: [],
    countries: [],
    regions: [],
    errors: {},
    selectedFiles: [],
    type: null,
    disabled: false,
    isSubmitting: false
};

const getters = {
    getType: (state) => {
        return state.type;
    },
    getField: (state) => (id) => {
        return state.formData.find((field) => field.id === id) || {};
    },
    getFieldValue: (state) => (id) => {
        const field = state.formData.find((field) => field.id === id);
        return field ? field.value : null;
    },
    getFields(state) {
        return state.formData;
    },
    getFormData:
        (state) =>
        (fieldsToInclude = false) => {
            if (!fieldsToInclude) {
                return state.formData;
            }
            const fields = state.formData.filter((field) => fieldsToInclude.includes(field.id) || fieldsToInclude.includes(field.key));
            return fields;
        },
    getErrors: (state) => state.errors,
    getFileUuid: (state) => (index) => {
        return state.selectedFiles[index].uuid;
    },
    getIsDisabled: (state) => {
        return state.disabled;
    },
    getValidationRules: (state) => (fieldId) => {
        const field = state.formData.find((f) => f.id === fieldId);
        return field ? field.validation_rules || [] : [];
    },
    getIsSubmitting: (state) => state.isSubmitting,
    shouldHideField: (state, getters, rootGetters) => (id) => {
        const field = state.formData.find((field) => field.id === id);
        if (!field || !field.validation_rules) return false;

        if (field.internal_only && !rootGetters.auth.isInternal) {
            return true;
        }
        const authIfRoleRules = field.validation_rules.filter((rule) => rule.startsWith('auth_if_role:'));
        for (let rule of authIfRoleRules) {
            rule = rule.replace('auth_if_role:', '');
            const [role] = rule.split(',');
            if (parseInt(rootGetters.auth.user.role.id) === parseInt(role)) {
                return false;
            } else {
                return true;
            }
        }
        const authIfCanRules = field.validation_rules.filter((rule) => rule.startsWith('auth_if_ability:'));
        for (let rule of authIfCanRules) {
            rule = rule.replace('auth_if_ability:', '');
            const [ability] = rule.split(',');
            if (rootGetters.auth.user.role.abilities.some((a) => a.name === ability)) {
                return false;
            } else {
                return true;
            }
        }

        // Check hide_if conditions
        const hideIfRules = field.validation_rules.filter((rule) => rule.startsWith('hide_if:'));
        for (let rule of hideIfRules) {
            rule = rule.replace('hide_if:', '');
            const [fieldId, ...values] = rule.split(',');
            const conditionalField = state.formData.find((f) => f.id === fieldId);

            // if the parent is hidden, hide the child
            if (conditionalField && getters.shouldHideField(conditionalField.id)) {
                return true;
            }

            // if the conditional field's value is an array, check if any values match any of the specified values
            if (conditionalField && Array.isArray(conditionalField.value)) {
                if (values.some((value) => conditionalField.value.includes(value))) {
                    return true; // Hide the field if any hide_if condition is met
                }
            }

            // Check if the conditional field's value matches any of the specified values
            if (conditionalField && values.includes(conditionalField.value)) {
                return true; // Hide the field if any hide_if condition is met
            }
        }

        // Check hide_unless conditions
        const hideUnlessRules = field.validation_rules.filter((rule) => rule.startsWith('hide_unless:'));
        if (hideUnlessRules.length === 0) return false;
        for (let rule of hideUnlessRules) {
            rule = rule.replace('hide_unless:', '');
            const [fieldId, ...values] = rule.split(',');
            const conditionalField = state.formData.find((f) => f.id === fieldId);

            // if the parent is hidden, hide the child
            if (conditionalField && getters.shouldHideField(conditionalField.id)) {
                return true;
            }

            // if the conditional field's value is an array, check if any values match any of the specified values
            if (conditionalField && Array.isArray(conditionalField.value)) {
                if (values.some((value) => conditionalField.value.includes(value))) {
                    return false; // Hide the field if any hide_unless condition is met
                }
            }

            // Check if the conditional field's value matches any of the specified values
            if (conditionalField && values.includes(conditionalField.value)) {
                return false; // Hide the field if any hide_unless condition is met
            }
        }
        return true;
    },

    isRequiredField: (state) => (id) => {
        const field = state.formData.find((field) => field.id === id);
        if (!field || !field.validation_rules) return false;

        if (field.validation_rules.includes('required')) {
            return true;
        }

        const requiredIfRules = field.validation_rules.filter((rule) => rule.startsWith('required_if:'));
        const isRequiredIf = requiredIfRules.some((requiredIfCondition) => {
            const condition = requiredIfCondition.replace('required_if:', '');
            const [fieldId, value] = condition.split(',');
            const conditionalField = state.formData.find((field) => field.id === fieldId);
            return conditionalField && conditionalField.value === value;
        });
        if (isRequiredIf) {
            return true;
        }

        const requiredWithRules = field.validation_rules.filter((rule) => rule.startsWith('required_with:'));
        const isRequiredWith = requiredWithRules.some((requiredWithCondition) => {
            const requiredWithFieldId = requiredWithCondition.replace('required_with:', '');
            const relatedField = state.formData.find((field) => field.id === requiredWithFieldId);

            return relatedField && relatedField.value;
        });

        return isRequiredWith;
    },
    getNumberOfFieldsInGroup: (state) => (fieldGroup) => {
        return state.formData.filter((field) => field.field_group === fieldGroup).length;
    }
};

const actions = {
    resetForm({ commit }) {
        commit('setFields', []);
        commit('clearErrors');
        commit('clearSelectedFiles');
    },
    setErrors({ commit }, errors) {
        commit('setErrors', errors);
    },
    clearErrors({ commit }) {
        commit('clearErrors');
    },
    duplicateGroup({ commit, state }, { fields, repeatCount = 1 }) {
        const lastFieldId = fields[fields.length - 1].id;
        const lastFieldIndex = state.formData.findIndex((field) => field.id === lastFieldId);

        for (let i = 0; i < repeatCount; i++) {
            const newFields = fields.map((field) => {
                const newField = { ...field };
                newField.iteration = (newField.iteration || 0) + i + 1;
                newField.id = field.id.replace(/.\d*$/, `.${newField.iteration}`);
                newField.order = field.order + 0.01 * (i + 1);
                newField.value = null;

                if (field.default_value) {
                    if (field.default_value == 'true') {
                        newField.value = true;
                    } else if (field.default_value == 'false') {
                        newField.value = false;
                    } else {
                        newField.value = field.default_value;
                    }
                }

                if (field.field_group) {
                    newField.field_group = field.field_group + 0.01 * (i + 1);
                }

                return newField;
            });

            commit('insertFieldsAfter', { index: lastFieldIndex + fields.length * i, newFields });
        }
    },
    removeGroup({ commit, state }, fields) {
        const fieldGroup = Math.round(fields[0].field_group);
        const iteration = fields[0].iteration;

        // Remove the fields
        commit(
            'removeFields',
            fields.map((field) => field.id)
        );

        // Update iteration for fields with higher iteration within the same group
        state.formData.forEach((field, index) => {
            if (Math.round(field.field_group) === fieldGroup && field.iteration > iteration) {
                commit('updateFieldIteration', { fieldId: field.id, iteration: field.iteration - 1 });
            }
        });
    },
    async fetchCountries({ commit }) {
        try {
            const response = await getData(`/api/meta/countries`);
            commit('setCountries', response);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    },
    async fetchRegionsForCountry({ commit }, countryId) {
        try {
            const response = await getData(`/api/meta/countries/${countryId}/regions`);
            commit('setRegions', response);
        } catch (error) {
            console.error(`Error fetching regions for country ${countryId}:`, error);
        }
    },
    async fetchAllRegions({ commit }) {
        try {
            const response = await getData(`/api/meta/regions`);
            commit('setRegions', response);
        } catch (error) {
            console.error(`Error fetching regions`, error);
        }
    },
    async submitFormData({ state, commit }, { url, requestType, additionalData = {}, keysToSubmit = false }) {
        try {
            commit('setIsSubmitting', true);
            commit('clearErrors');

            let useFormData = false;
            const formData = new FormData();
            const formattedData = {};

            state.formData.forEach((field) => {
                field.key = field.key || field.id;

                if (keysToSubmit && !keysToSubmit.includes(field.key)) {
                    return;
                }

                let fieldValue = field.value === null ? '' : field.value;

                if (!fieldValue) {
                    return;
                }

                if (field.iteration !== undefined) {
                    // Handle repeatable fields
                    if (!Array.isArray(formData.get(field.key))) {
                        formData.set(field.key, []);
                    }
                    formData.append(field.key, fieldValue);
                    formattedData[field.key] = formattedData[field.key] || [];
                    formattedData[field.key].push(fieldValue);
                } else if (Array.isArray(fieldValue) && fieldValue[0] instanceof File) {
                    // Handle multifile fields
                    useFormData = true;
                    fieldValue.forEach((file, index) => {
                        formData.append(`${field.key}[${index}]`, file);
                    });
                } else if (Array.isArray(fieldValue)) {
                    // Handle other arrays (like multiselects)
                    fieldValue.forEach((item, index) => {
                        formData.append(`${field.key}[${index}]`, item);
                        formattedData[field.key] = formattedData[field.key] || [];
                        formattedData[field.key].push(item);
                    });
                } else if (fieldValue instanceof File) {
                    // Handle single file fields
                    useFormData = true;
                    formData.append(field.key, fieldValue);
                } else {
                    // Handle other fields
                    formData.append(field.key, fieldValue);
                    formattedData[field.key] = fieldValue;
                }
            });

            // Add additional data
            for (let key in additionalData) {
                formData.append(key, additionalData[key]);
                formattedData[key] = additionalData[key];
            }

            let response;
            const dataToSubmit = useFormData ? formData : { ...formattedData, ...additionalData };

            if (requestType.toLowerCase() === 'post') {
                response = await postData(url, dataToSubmit);
            } else if (requestType.toLowerCase() === 'patch') {
                response = await patchData(url, dataToSubmit);
            } else {
                throw new Error(`Unsupported request type: ${requestType}`);
            }

            return response;
        } catch (error) {
            console.error('Error submitting form:', error);

            if (error.response && error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            }

            throw error;
        } finally {
            commit('setIsSubmitting', false);
        }
    }
};

const mutations = {
    setType(state, newType) {
        state.type = newType;
    },
    setField(state, { id, value }) {
        const fieldIndex = state.formData.findIndex((field) => field.id === id);
        if (fieldIndex !== -1) {
            state.formData[fieldIndex] = value;
        } else {
            state.formData.push({ id, ...value });
        }
    },
    setFieldValue(state, { id, value }) {
        const fieldIndex = state.formData.findIndex((field) => field.id === id);
        if (fieldIndex !== -1) {
            state.formData[fieldIndex].value = value;
        } else {
            state.formData.push({ id, value });
        }
    },
    setFieldValidationRules(state, { id, rules }) {
        const fieldIndex = state.formData.findIndex((field) => field.id === id);
        if (fieldIndex !== -1) {
            state.formData[fieldIndex].validation_rules = rules;
        } else {
            state.formData.push({ id, validation_rules: rules });
        }
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
    setFieldErrors(state, { fieldId, errors }) {
        state.errors[fieldId] = errors;
    },
    clearErrors(state) {
        state.errors = {};
    },
    clearFieldErrors(state, fieldId) {
        if (!state.errors) {
            state.errors = {};
        }
        state.errors = Object.keys(state.errors).reduce((newErrors, key) => {
            if (key !== fieldId) {
                newErrors[key] = state.errors[key];
            }
            return newErrors;
        }, {});
    },
    addFields(state, newFields) {
        state.formData = [...state.formData, ...newFields];
    },
    insertFieldsAfter(state, { index, newFields }) {
        state.formData.splice(index + 1, 0, ...newFields);
    },
    removeFields(state, fieldIds) {
        state.formData = state.formData.filter((field) => !fieldIds.includes(field.id));
    },
    initializeFormData(state, formData) {
        state.formData = formData;
    },
    setFields(state, fields) {
        state.formData = [];
        const addFieldsRecursively = (fields) => {
            fields.forEach((field) => {
                if (Array.isArray(field)) {
                    addFieldsRecursively(field);
                } else {
                    state.formData.push(field);
                }
            });
        };
        addFieldsRecursively(fields);
    },
    addBaseFields(state, baseFields) {
        const addFieldsRecursively = (fields) => {
            fields.forEach((field) => {
                if (Array.isArray(field)) {
                    addFieldsRecursively(field);
                } else {
                    state.formData.push(field);
                }
            });
        };
        addFieldsRecursively(baseFields);
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    setRegions(state, regions) {
        state.regions = regions;
    },
    addSelectedFiles(state, files) {
        state.selectedFiles.push(...files);

        // set the value of the file field to the file
        files.forEach((file) => {
            const field = state.formData.find((field) => field.id === file.fieldId);
            if (field) {
                if (field.field_type === 'multifile') {
                    if (!Array.isArray(field.value)) {
                        field.value = [];
                    }
                    field.value.push(file.file);
                } else {
                    field.value = file.file;
                }
            }
        });
    },
    removeSelectedFile(state, index) {
        state.selectedFiles.splice(index, 1);
    },
    clearSelectedFiles(state) {
        state.selectedFiles = [];
    },
    updateFileUuid(state, { index, uuid }) {
        state.selectedFiles[index].uuid = uuid;
    },
    setDisabled(state, disabled) {
        state.disabled = disabled;
    },
    setIsSubmitting(state, isSubmitting) {
        state.isSubmitting = isSubmitting;
    },
    updateFieldIteration(state, { fieldId, iteration }) {
        const fieldIndex = state.formData.findIndex((field) => field.id === fieldId);
        if (fieldIndex !== -1) {
            const field = state.formData[fieldIndex];
            field.iteration = iteration;
            field.id = field.id.replace(/.\d*$/, `.${iteration}`);
        }
    },
    addFileFieldUUID(state, { fieldId, uuid }) {
        const field = state.formData.find((field) => field.id === fieldId);
        if (field) {
            if (!Array.isArray(field.value)) {
                field.value = [];
            }
            field.value.push(uuid);
        }
    },
    removeFileFieldUUID(state, { fieldId, uuid }) {
        const field = state.formData.find((field) => field.id === fieldId);
        if (field && Array.isArray(field.value)) {
            const index = field.value.indexOf(uuid);
            if (index !== -1) {
                field.value.splice(index, 1);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
