export default [
    {
        path: 'accounting',
        meta: { title: 'MyMadrivo | Accounting' },
        children: [
            {
                path: '/internal/accounting/payment-holds',
                name: 'paymentHolds',
                component: () => import('@/views/pages/Accounting/PaymentHolds.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'viewPaymentHolds' }
            },
            {
                path: '/internal/accounting/reconciliation',
                name: 'reconciliation',
                component: () => import('@/views/pages/Reconciliation/Index.vue'),
                meta: { isInternal: true, requiresAbility: 'viewReconciliations', requiresAuth: true }
            },
            {
                path: '/internal/accounting/reconciliation/status',
                name: 'reconciliationStatus',
                component: () => import('@/views/pages/Reconciliation/Status.vue'),
                meta: { isInternal: true, requiresAbility: 'viewReconciliations', requiresAuth: true }
            },
            {
                path: '/internal/accounting/reconciliation/:id',
                name: 'reconciliationDetail',
                component: () => import('@/views/pages/Reconciliation/Details.vue'),
                props: (route) => ({ id: route.params.id }),
                meta: { isInternal: true, requiresAbility: 'viewReconciliations', requiresAuth: true }
            }
        ]
    }
];
