import { getData } from '@/api';

const state = {
    search: '',
    profile: {},
    profileIsLoading: true,
    offers: [],
    offersAreLoading: true,
    selectedOfferId: null
};

const getters = {
    search: (state) => state.search,
    profile: (state) => state.profile,
    profileIsLoading: (state) => state.profileIsLoading,
    offers: (state) => state.offers,
    offersAreLoading: (state) => state.offersAreLoading,
    selectedOfferId: (state) => state.selectedOfferId,
    selectedOffer: (state) => state.offers.find((offer) => offer.id === state.selectedOfferId),
    advertiserData: (state) => {
        const data = state.profile?.advertiser_data;

        if (!data) {
            return {};
        }

        return typeof data === 'string' ? JSON.parse(data) : data;
    },
    id: (state) => state.profile.network_advertiser_id,
    encryptedId: (state) => state.profile.encrypted_id,
    hubspot_company_id: (state) => state.profile?.hubspot_company?.hubspot_id ?? null
};

const actions = {
    setSearch({ commit }, search) {
        commit('setSearch', search);
    },
    clearSearch({ commit }) {
        commit('setSearch', '');
    },
    setProfile({ commit }, profile) {
        commit('setProfile', profile);
    },
    clearProfile({ commit }) {
        commit('setProfile', {});
    },
    setProfileIsLoading({ commit }, isLoading) {
        commit('setProfileIsLoading', isLoading);
    },
    setOffers({ commit }, offers) {
        commit('setOffers', offers);
    },
    clearOffers({ commit }) {
        commit('setOffers', []);
    },
    setOffersAreLoading({ commit }, isLoading) {
        commit('setOffersAreLoading', isLoading);
    },
    reset({ commit }) {
        commit('reset');
    },
    setSelectedOfferId({ commit }, selectedOfferId) {
        commit('setSelectedOfferId', selectedOfferId);
    },
    async fetchProfile({ commit, state }, id) {
        if (state.profile.network_advertiser_id === id) {
            return; // Don't re-fetch the same advertiser
        }
        commit('setProfileIsLoading', true);
        try {
            const data = await getData(`/api/advertisers/${id}`);
            console.log('Advertiser Profile:', data);
            if (!data.advertiser) {
                throw new Error('Advertiser not found');
            }
            commit('setProfile', data.advertiser);
        } catch (error) {
            console.log(error);
        } finally {
            commit('setProfileIsLoading', false);
        }
    },

    async fetchOffers({ commit }, id) {
        commit('setOffersAreLoading', true);
        try {
            const data = await getData(`/api/advertisers/${id}/offers`);
            console.log('Advertiser Offers:', data);
            commit('setOffers', data);
        } catch (error) {
            console.log(error);
        } finally {
            commit('setOffersAreLoading', false);
        }
    }
};

const mutations = {
    setSearch(state, search) {
        state.search = search;
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    setProfileIsLoading(state, isLoading) {
        state.profileIsLoading = isLoading;
    },
    setOffers(state, offers) {
        state.offers = offers;
    },
    setOffersAreLoading(state, isLoading) {
        state.offersAreLoading = isLoading;
    },
    reset(state) {
        state.search = '';
        state.profile = {};
        state.profileIsLoading = true;
        state.offers = [];
        state.offersAreLoading = true;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
